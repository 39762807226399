import React from "react"

import { ErrorMessage } from "@hookform/error-message"
import { Stack } from "@mui/material"
import FormHelperText from "@mui/material/FormHelperText"
import TextField from "@mui/material/TextField"
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker"
import { Dayjs } from "dayjs"
import { Controller } from "react-hook-form"

import HelperText from "@components/forms/default/HelperText"
import { InputContainerDiv } from "@components/forms/default/InputTextField"
import Label from "@components/forms/default/Label"

interface DatepickerProps {
  control: any // eslint-disable-line @typescript-eslint/no-explicit-any
  defaultValue?: Dayjs
  disabled?: boolean
  errors?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  helperText?: string
  label?: string
  loading?: boolean
  name: string
  noBorder?: boolean
  onChange: (date: any) => void // eslint-disable-line @typescript-eslint/no-explicit-any
  placeholder?: string
  required?: boolean
  dataCy?: string
  sx?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  sxWrapper?: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

const Datepicker: React.FC<DatepickerProps> = ({
  control,
  defaultValue,
  disabled,
  errors,
  helperText,
  label,
  loading,
  name,
  noBorder,
  onChange,
  placeholder,
  required,
  sx,
  sxWrapper,
  dataCy,
}: DatepickerProps) => {
  if (loading) {
    return (
      <InputContainerDiv sx={sxWrapper} data-cy={dataCy}>
        {label && <Label required={required}>{label}</Label>}
        <TextField
          data-testid="skeleton-input"
          size="small"
          sx={{ width: "100%", ...sx }}
          variant="outlined"
          disabled
        />
      </InputContainerDiv>
    )
  }

  return (
    <InputContainerDiv sx={sxWrapper} data-cy={dataCy}>
      {label && <Label required={required}>{label}</Label>}
      {helperText && <HelperText>{helperText}</HelperText>}

      <Controller
        control={control}
        defaultValue={defaultValue}
        data-cy={dataCy}
        disabled={disabled}
        name={name}
        render={({ field }) => (
          <Stack>
            <MuiDatePicker
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
              slotProps={{
                textField: {
                  size: "small",
                  error: !!errors[name],
                  ...(noBorder && { variant: "standard" }),
                  InputProps: {
                    ...(placeholder && { placeholder }),
                    ...(noBorder && { disableUnderline: true }),
                  },
                },
              }}
              onChange={onChange}
              sx={{ ml: "auto", ...sx }}
            />

            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <FormHelperText error>{message}</FormHelperText>
              )}
            />
          </Stack>
        )}
      />
    </InputContainerDiv>
  )
}

export default Datepicker
