import React, { useEffect, useState } from "react"

import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"

import { AddressType } from "@components/AddressRow"
import { Checkbox, InputGroup, InputTextField } from "@components/forms/default"
import CountriesSelect from "@components/preloadedSelects/CountriesSelect"
import ProvinceSelect from "@components/preloadedSelects/ProvinceSelect"

const SAME_AS_BILLING = "useBillingAsShipping"
const SHIPPING = "shipping"
const BILLING = "billing"

interface AddressFieldsProps {
  address?: AddressType | null
  control: any // eslint-disable-line @typescript-eslint/no-explicit-any
  errors: any // eslint-disable-line @typescript-eslint/no-explicit-any
  addressType?: string
  selectedCountry: string
  watch: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

const AddressFields: React.FC<AddressFieldsProps> = ({
  address,
  control,
  errors,
  addressType,
  selectedCountry,
  watch,
}: AddressFieldsProps) => {
  const { t } = useTranslation()

  return (
    <>
      <CountriesSelect
        control={control}
        defaultValue={selectedCountry}
        errors={errors}
        name={
          addressType === SHIPPING
            ? `${SHIPPING}Country`
            : `${addressType}Country`
        }
      />

      <Box>
        <InputTextField
          control={control}
          defaultValue={address?.addressLineOne || ""}
          errors={errors}
          label={t("addressBlock_addressLabel")}
          name={
            addressType === SHIPPING
              ? `${SHIPPING}Address1`
              : `${addressType}Address1`
          }
          placeholder={t("addressBlock_addressOnePlaceholder")}
        />
        <InputTextField
          control={control}
          defaultValue={address?.addressLineTwo || ""}
          errors={errors}
          name={
            addressType === SHIPPING
              ? `${SHIPPING}Address2`
              : `${addressType}Address2`
          }
          placeholder={t("addressBlock_addressTwoPlaceholder")}
          sx={{ marginTop: "8px" }}
        />
      </Box>

      <InputGroup>
        <InputTextField
          control={control}
          defaultValue={address?.city || undefined}
          errors={errors}
          label={t("addressBlock_cityLabel")}
          name={
            addressType === SHIPPING ? `${SHIPPING}City` : `${addressType}City`
          }
        />
        <ProvinceSelect
          control={control}
          defaultCountry={selectedCountry}
          defaultValue={address?.state || ""}
          errors={errors}
          name={
            addressType === SHIPPING
              ? `${SHIPPING}State`
              : `${addressType}State`
          }
          watch={watch}
        />
        <InputTextField
          control={control}
          defaultValue={address?.zip || ""}
          errors={errors}
          label={t("addressBlock_zipLabel")}
          name={
            addressType === SHIPPING ? `${SHIPPING}Zip` : `${addressType}Zip`
          }
          sx={{ maxWidth: "120px" }}
        />
      </InputGroup>
    </>
  )
}

interface AddressBlockProps {
  address?: AddressType | null
  addressType?: string
  companyType?: string
  control: any // eslint-disable-line @typescript-eslint/no-explicit-any
  errors: any // eslint-disable-line @typescript-eslint/no-explicit-any
  getValues: any // eslint-disable-line @typescript-eslint/no-explicit-any
  // addressType needs to be defined for the country dropdown to affect the province dropdown.
  // notAssigned is a workaround to hide some things even when the address type is defined.
  notAssigned?: boolean
  watch: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

const AddressBlock: React.FC<AddressBlockProps> = ({
  address,
  addressType,
  companyType,
  control,
  errors,
  getValues,
  notAssigned,
  watch,
}: AddressBlockProps) => {
  const { t } = useTranslation()

  const [selectedCountry, setSelectedCountry] = useState(
    address?.country || "US",
  )
  const [selectedShippingCountry, setSelectedShippingCountry] = useState("US")
  const [selectedCheckbox, setSelectedCheckbox] = useState(true)

  const watchedCountryField = watch(`${addressType}Country`)
  const watchedShippingCountryField = watch(`${SHIPPING}Country`)
  const watchedSameTypeField = watch(SAME_AS_BILLING)

  // sets the non-shipping country dropdown with a default value
  // needed to set this manually to affect the province dropdown
  useEffect(() => {
    if (addressType !== SHIPPING) {
      const defaultCountry = getValues(`${addressType}Country`)

      if (defaultCountry?.code) {
        setSelectedCountry(defaultCountry.code)
      } else if (defaultCountry !== undefined) {
        setSelectedCountry(defaultCountry)
      }
    }
  }, [watchedCountryField, addressType, getValues])

  // sets the shipping country dropdown with a default value
  // needed to set this manually to affect the province dropdown
  useEffect(() => {
    if (addressType === SHIPPING) {
      const defaultCountry = getValues(`${SHIPPING}Country`)
      if (defaultCountry?.code) {
        setSelectedShippingCountry(defaultCountry.code)
      } else if (defaultCountry !== undefined) {
        setSelectedShippingCountry(defaultCountry)
      }
    }
  }, [watchedShippingCountryField, addressType, getValues])

  // react-hook-form needs state to actively change to affect already rendered components
  useEffect(() => {
    setSelectedCheckbox(watchedSameTypeField)
  }, [watchedSameTypeField])

  return (
    <>
      {addressType && !notAssigned && (
        <Box>
          <Typography
            variant="body1"
            sx={{ mt: 0, mb: 0, textTransform: "capitalize" }}
          >
            {addressType}
          </Typography>
          <Typography variant="caption" sx={{ mt: 0, mb: 0 }}>
            {t("addressBlock_addressTypeText", {
              addressType,
              companyType,
            })}
          </Typography>
        </Box>
      )}

      {addressType === SHIPPING && (
        <Checkbox
          control={control}
          errors={errors}
          defaultValue={selectedCheckbox}
          label={t("addressBlock_sameAsBillingAddressLabel")}
          name={SAME_AS_BILLING}
        />
      )}

      {addressType !== SHIPPING && (
        <AddressFields
          address={address}
          control={control}
          errors={errors}
          addressType={BILLING}
          selectedCountry={selectedCountry}
          watch={watch}
        />
      )}

      {!selectedCheckbox && addressType === SHIPPING && (
        <AddressFields
          control={control}
          errors={errors}
          addressType={SHIPPING}
          selectedCountry={selectedShippingCountry}
          watch={watch}
        />
      )}
    </>
  )
}

export default AddressBlock
