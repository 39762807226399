import React, { useState } from "react"

import { ErrorMessage } from "@hookform/error-message"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import FormHelperText from "@mui/material/FormHelperText"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import OutlinedInput from "@mui/material/OutlinedInput"
import TextField from "@mui/material/TextField"
import { styled } from "@mui/system"
import { Controller } from "react-hook-form"

import Label from "../Label"

export const InputContainerDiv = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
})

interface PasswordInputProps {
  control?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  defaultValue?: string
  errors?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  label?: string
  loading?: boolean
  multiline?: boolean
  name: string
  placeholder?: string
  required?: boolean
  rows?: number
  sx?: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  control,
  defaultValue = "",
  errors = [],
  label,
  loading,
  multiline,
  name,
  placeholder,
  required,
  rows,
  sx,
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }

  if (loading) {
    return (
      <InputContainerDiv>
        {label && <Label required={required}>{label}</Label>}
        <TextField
          data-testid="skeleton-input"
          size="small"
          sx={{ width: "100%" }}
          variant="outlined"
          disabled
        />
      </InputContainerDiv>
    )
  }

  return (
    <InputContainerDiv style={sx}>
      {label && <Label required={required}>{label}</Label>}
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({ field }) => (
          <>
            <OutlinedInput
              data-testid="textfield-input"
              error={!!errors[name]}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              multiline={multiline}
              placeholder={placeholder}
              rows={rows}
              size="small"
              sx={{ width: "100%" }}
              type={showPassword ? "text" : "password"}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
            />

            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <FormHelperText error>{message}</FormHelperText>
              )}
            />
          </>
        )}
      />
    </InputContainerDiv>
  )
}

export default PasswordInput
