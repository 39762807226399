import React from "react"

import { SxProps, Theme } from "@mui/material"

import { InputNumericField } from "@components/forms/default"
import { getCurrencySymbol } from "@components/preloadedSelects/CurrencyAutoComplete"

interface InputCurrencyFieldProps {
  control: any // eslint-disable-line @typescript-eslint/no-explicit-any
  currencyCode?: string
  errors: any // eslint-disable-line @typescript-eslint/no-explicit-any
  name: string
  loadingQuery: boolean

  // NOT REQUIRED
  disabled?: boolean
  label?: string
  horizontal?: boolean
  muted?: boolean
  placeholder?: string
  removeBorder?: boolean
  sxWrapper?: SxProps<Theme>
  sx?: SxProps<Theme>
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const InputCurrencyField: React.FC<InputCurrencyFieldProps> = ({
  control,
  currencyCode,
  disabled = false,
  errors,
  label,
  name,
  horizontal,
  loadingQuery,
  muted,
  placeholder,
  removeBorder,
  sxWrapper = {},
  sx = {},
  onChange,
}: InputCurrencyFieldProps) => {
  const currencySymbol = getCurrencySymbol(currencyCode)

  const sxLabel = {
    ...(muted && { fontWeight: "unset", fontSize: "13px" }),
  }
  const combinedSxWrapper = {
    ...(muted && { opacity: 0.7 }),
    ...sxWrapper,
  }
  const combinedSx = { ml: "auto", ...sx }

  return (
    <InputNumericField
      control={control}
      currencyCode={currencyCode}
      disabled={disabled}
      errors={errors}
      horizontal={horizontal}
      isCurrency
      label={label}
      loading={loadingQuery}
      name={name}
      placeholder={placeholder || `${currencySymbol}0.00`}
      removeBorder={removeBorder}
      sx={combinedSx}
      sxLabel={sxLabel}
      sxWrapper={combinedSxWrapper}
      onChange={onChange}
    />
  )
}

export default InputCurrencyField
