import React from "react"

import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

import { BillStatus, InvoiceStatus } from "@gql/graphql"

const toTitleCase = (str: string) => {
  // Replace underscores with spaces
  let modifiedStr = str?.replace(/_/g, " ")
  // Insert a space before any uppercase letter that follows a lowercase letter
  modifiedStr = modifiedStr?.replace(/([a-z])([A-Z])/g, "$1 $2")
  // Insert a space before any uppercase letter that follows another uppercase letter and is followed by a lowercase letter
  modifiedStr = modifiedStr?.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
  // Capitalize the first letter of each word
  modifiedStr = modifiedStr?.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
  })
  return modifiedStr
}

type PillColor = {
  bgColor: string
  fontColor: string
  borderColor?: string
}

export const pillColors: Record<string, PillColor> = {
  green: {
    bgColor: "#CDFEE1",
    fontColor: "#1B8856",
  },
  pink: {
    bgColor: "#F1B6B5",
    fontColor: "#000000",
  },
  orange: {
    bgColor: "#F8D6AA",
    fontColor: "#1C1C1C",
  },
  yellow: {
    bgColor: "#FCEEA7",
    fontColor: "#1C1C1C",
  },
  purple: {
    bgColor: "#E5E2FD",
    fontColor: "#6823F5",
  },
  blue: {
    bgColor: "#E3EFFD",
    fontColor: "#205279",
  },
  gray: {
    bgColor: "#E8E8E8",
    fontColor: "#616161",
  },
  red: {
    bgColor: "#FF7B7B",
    fontColor: "#474747",
  },
  white: {
    bgColor: "#FFFFFF",
    fontColor: "#474747",
    borderColor: "#DEDEDE",
  },
  lavender: {
    bgColor: "#F6EDF7",
    fontColor: "#281C64",
  },
}

const stateColors: Record<string, PillColor> = {
  active: pillColors.green,
  pending: pillColors.yellow,
  suspended: pillColors.orange,

  // PO states
  awaiting_shipment: pillColors.orange,
  partially_received: pillColors.yellow,
  received: pillColors.green,

  // order states
  draft: pillColors.purple,
  unfulfilled: pillColors.yellow,
  partially_fulfilled: pillColors.orange,

  // payment states
  not_paid: pillColors.orange,
  partially_paid: pillColors.yellow,
  payment_pending: pillColors.yellow,
  overdue: pillColors.red,
  disputed: pillColors.purple,

  // item hierarchies
  parent: pillColors.purple,
  variant: pillColors.blue,
  single: pillColors.yellow,

  // inventory states
  depleted: pillColors.purple,
  quarantined: pillColors.pink,

  // inventory receiving types
  accepted: pillColors.green,
  rejected: pillColors.red,

  // item availability states
  unavailable: pillColors.red,
  fully_available: pillColors.green,
  partially_available: pillColors.yellow,

  // picking & packing states
  not_started: pillColors.red,
  fully_picked: pillColors.green,
  partially_picked: pillColors.yellow,
  fully_packed: pillColors.green,
  partially_packed: pillColors.yellow,

  // job status states
  // not_started (already exists)
  in_progress: pillColors.yellow,
  completed: pillColors.green,

  // job priority states
  rush: pillColors.red,
  normal: pillColors.gray,

  // nested inventory states
  nested: pillColors.orange,
  child: pillColors.purple,

  // jobs
  job_number: pillColors.lavender,

  // quality check states
  pass: pillColors.green,
  fail: pillColors.red,

  // shipment states
  awaiting_shipment_creation: pillColors.yellow,
  awaiting_carrier: pillColors.blue,
  awaiting_pickup: pillColors.pink,
  awaiting_drop_off: pillColors.orange,

  // custom label types
  standard: pillColors.blue,
  custom: pillColors.orange,

  // quote states
  sent: pillColors.orange,
  revisionsNeeded: pillColors.yellow,
  expired: pillColors.red,
  closedWon: pillColors.green,
  closedLost: pillColors.gray,

  // invoice states
  [InvoiceStatus.Invoiced.toLowerCase()]: pillColors.green,
  [InvoiceStatus.InvoicePending.toLowerCase()]: pillColors.yellow,
  [InvoiceStatus.NotInvoiced.toLowerCase()]: pillColors.gray,

  // bill states
  [BillStatus.Billed.toLowerCase()]: pillColors.green,
  [BillStatus.BillPending.toLowerCase()]: pillColors.yellow,
  [BillStatus.NotBilled.toLowerCase()]: pillColors.gray,
}

interface PillProps {
  status: string

  color?: string
  dataCy?: string
  icon?: React.ReactNode
  sx?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  label?: string | React.ReactNode
  onClick?: () => void
}

const Pill: React.FC<PillProps> = ({
  color,
  dataCy,
  icon,
  label,
  onClick,
  status,
  sx,
}: PillProps) => {
  let finalLabel = label
  const key = typeof status === "string" ? status?.toLowerCase() : "unknown"
  let stateColor = stateColors[key]

  if (stateColor === undefined) {
    stateColor = pillColors.gray
  }

  // if color is passed in override them all
  if (color) {
    stateColor = pillColors[color]
  }

  if (label && typeof label === "string") {
    finalLabel = toTitleCase(label)
  }

  return (
    <Box
      data-testid="pill"
      sx={{
        borderRadius: "8px",
        backgroundColor: stateColor?.bgColor,
        border: stateColor?.borderColor
          ? `1px solid ${stateColor?.borderColor}`
          : "",
        color: stateColor?.fontColor,
        padding: "2px 8px",
        textAlign: "center",
        width: "fit-content",
        ...(!!onClick && { cursor: "pointer" }),
        ...sx,
      }}
      onClick={onClick}
    >
      <Typography
        data-cy={dataCy || "status"}
        sx={{
          alignItems: "center",
          display: "flex",
          fontSize: "12px",
          fontWeight: 500,
          textTransform: "capitalize",
        }}
      >
        {icon}
        {finalLabel || toTitleCase(status)}
      </Typography>
    </Box>
  )
}

export default Pill
